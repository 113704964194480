exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-coupons-2-tsx": () => import("./../../../src/pages/admin/coupons2.tsx" /* webpackChunkName: "component---src-pages-admin-coupons-2-tsx" */),
  "component---src-pages-admin-coupons-tsx": () => import("./../../../src/pages/admin/coupons.tsx" /* webpackChunkName: "component---src-pages-admin-coupons-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-booking-gera-pradzia-250106-tsx": () => import("./../../../src/pages/booking/gera_pradzia_250106.tsx" /* webpackChunkName: "component---src-pages-booking-gera-pradzia-250106-tsx" */),
  "component---src-pages-booking-index-tsx": () => import("./../../../src/pages/booking/index.tsx" /* webpackChunkName: "component---src-pages-booking-index-tsx" */),
  "component---src-pages-booking-private-tsx": () => import("./../../../src/pages/booking/private.tsx" /* webpackChunkName: "component---src-pages-booking-private-tsx" */),
  "component---src-pages-booking-test-tsx": () => import("./../../../src/pages/booking/test.tsx" /* webpackChunkName: "component---src-pages-booking-test-tsx" */),
  "component---src-pages-card-tsx": () => import("./../../../src/pages/card.tsx" /* webpackChunkName: "component---src-pages-card-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-first-time-tsx": () => import("./../../../src/pages/first-time.tsx" /* webpackChunkName: "component---src-pages-first-time-tsx" */),
  "component---src-pages-gift-tsx": () => import("./../../../src/pages/gift.tsx" /* webpackChunkName: "component---src-pages-gift-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kids-tsx": () => import("./../../../src/pages/kids.tsx" /* webpackChunkName: "component---src-pages-kids-tsx" */),
  "component---src-pages-login-callback-tsx": () => import("./../../../src/pages/login-callback.tsx" /* webpackChunkName: "component---src-pages-login-callback-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */),
  "component---src-pages-projects-aukstyn-2024-index-tsx": () => import("./../../../src/pages/projects/aukstyn2024/index.tsx" /* webpackChunkName: "component---src-pages-projects-aukstyn-2024-index-tsx" */),
  "component---src-pages-projects-aukstyn-index-tsx": () => import("./../../../src/pages/projects/aukstyn/index.tsx" /* webpackChunkName: "component---src-pages-projects-aukstyn-index-tsx" */),
  "component---src-pages-projects-erasmus-2025-index-tsx": () => import("./../../../src/pages/projects/erasmus2025/index.tsx" /* webpackChunkName: "component---src-pages-projects-erasmus-2025-index-tsx" */),
  "component---src-pages-projects-erasmus-index-tsx": () => import("./../../../src/pages/projects/erasmus/index.tsx" /* webpackChunkName: "component---src-pages-projects-erasmus-index-tsx" */),
  "component---src-pages-projects-erasmus-project-tsx": () => import("./../../../src/pages/projects/erasmus/project.tsx" /* webpackChunkName: "component---src-pages-projects-erasmus-project-tsx" */),
  "component---src-pages-projects-erasmus-stazuote-tsx": () => import("./../../../src/pages/projects/erasmus/stazuote.tsx" /* webpackChunkName: "component---src-pages-projects-erasmus-stazuote-tsx" */),
  "component---src-pages-projects-es-index-tsx": () => import("./../../../src/pages/projects/es/index.tsx" /* webpackChunkName: "component---src-pages-projects-es-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-srf-index-tsx": () => import("./../../../src/pages/projects/srf/index.tsx" /* webpackChunkName: "component---src-pages-projects-srf-index-tsx" */),
  "component---src-pages-projects-srf-nemokamos-treniruotes-tsx": () => import("./../../../src/pages/projects/srf/nemokamos-treniruotes.tsx" /* webpackChunkName: "component---src-pages-projects-srf-nemokamos-treniruotes-tsx" */),
  "component---src-pages-projects-srf-vertical-auga-tsx": () => import("./../../../src/pages/projects/srf/vertical-auga.tsx" /* webpackChunkName: "component---src-pages-projects-srf-vertical-auga-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-start-tsx": () => import("./../../../src/pages/start.tsx" /* webpackChunkName: "component---src-pages-start-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-train-tsx": () => import("./../../../src/pages/train.tsx" /* webpackChunkName: "component---src-pages-train-tsx" */)
}

